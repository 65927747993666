import { db } from "../indexedDB";
import store from "../store";
import axiosInstance from "../axiosInstance";

const API_URL = process.env.VUE_APP_SERVICE_URL + "/api/my";

const MY_RUNNING_LOG_STORE_NAME = "my_vehicle_running_logs";

export const fetchAndStoreMyVehicleRunning = async () => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/vehicle-running-logs/all?is_paginated=false`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data.data;
        // Clear old data and store new data
        await db.clearStore(MY_RUNNING_LOG_STORE_NAME);
        data
          .slice(0, 20)
          .forEach((item) => db.addItem(MY_RUNNING_LOG_STORE_NAME, item));
        return data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${MY_RUNNING_LOG_STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    return await db.getAllItems(MY_RUNNING_LOG_STORE_NAME);
  }
};

export const getMyVehicleRunning = async (id) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/vehicle-running-logs/${id}`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        return response.data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${MY_RUNNING_LOG_STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    return await db.getItem(MY_RUNNING_LOG_STORE_NAME, id);
  }
};

const MY_REPAIR_LOG_STORE_NAME = "my_vehicle_repair_logs";

export const fetchAndStoreMyVehicleRepairs = async () => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/vehicle-repair-history/all?is_paginated=false`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data.data;
        // Clear old data and store new data
        await db.clearStore(MY_REPAIR_LOG_STORE_NAME);
        data
          .slice(0, 20)
          .forEach((item) => db.addItem(MY_REPAIR_LOG_STORE_NAME, item));
        return data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${MY_REPAIR_LOG_STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    return await db.getAllItems(MY_REPAIR_LOG_STORE_NAME);
  }
};

export const getMyVehicleRepair = async (id) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/vehicle-repair-history/${id}`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        return response.data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${MY_REPAIR_LOG_STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    return await db.getItem(MY_REPAIR_LOG_STORE_NAME, id);
  }
};

const MY_FUEL_LOG_STORE_NAME = "my_vehicle_fuel_logs";

export const fetchAndStoreMyVehicleFuel = async () => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/vehicle-fuel-history/all?is_paginated=false`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data.data;
        // Clear old data and store new data
        await db.clearStore(MY_FUEL_LOG_STORE_NAME);
        data
          .slice(0, 20)
          .forEach((item) => db.addItem(MY_FUEL_LOG_STORE_NAME, item));
        return data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${MY_FUEL_LOG_STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    return await db.getAllItems(MY_FUEL_LOG_STORE_NAME);
  }
};

export const getMyVehicleFuel = async (id) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/vehicle-fuel-history/${id}`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        return response.data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${MY_FUEL_LOG_STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    return await db.getItem(MY_FUEL_LOG_STORE_NAME, id);
  }
};

const MY_PROJECTS_STORE_NAME = "my_projects";

export const fetchAndStoreMyProjects = async () => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/projects/all?is_paginated=false`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data;
        // Clear old data and store new data
        await db.clearStore(MY_PROJECTS_STORE_NAME);
        data.forEach((item) => db.addItem(MY_PROJECTS_STORE_NAME, item));
        return data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${MY_PROJECTS_STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    return await db.getAllItems(MY_PROJECTS_STORE_NAME);
  }
};

export const getMyProject = async (id) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/projects/${id}`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        return response.data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${MY_PROJECTS_STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    return await db.getItem(MY_PROJECTS_STORE_NAME, id);
  }
};

const MY_VEHICLES_STORE_NAME = "my_vehicles";

export const fetchAndStoreMyVehicles = async () => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/vehicles/all?is_paginated=false`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data;
        // Clear old data and store new data
        await db.clearStore(MY_VEHICLES_STORE_NAME);
        data.forEach((item) => db.addItem(MY_VEHICLES_STORE_NAME, item));
        return data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${MY_VEHICLES_STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    return await db.getAllItems(MY_VEHICLES_STORE_NAME);
  }
};

export const getMyVehicle = async (id) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/vehicles/${id}`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        return response.data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${MY_VEHICLES_STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    return await db.getItem(MY_VEHICLES_STORE_NAME, id);
  }
};
